import React from 'react';
import { string, shape, bool, number } from 'prop-types';
import getConfig from 'next/config';
var Datadog = function Datadog(_ref) {
  var _serverConfig$datadog, _serverConfig$datadog2, _serverConfig$datadog3;
  var staticProps = _ref["static"];
  var clientToken = staticProps.clientToken,
    applicationId = staticProps.applicationId,
    service = staticProps.service,
    _staticProps$datadogU = staticProps.datadogUrl,
    datadogUrl = _staticProps$datadogU === void 0 ? 'us5.datadoghq.com' : _staticProps$datadogU,
    _staticProps$trackRes = staticProps.trackResources,
    trackResources = _staticProps$trackRes === void 0 ? true : _staticProps$trackRes,
    _staticProps$trackLon = staticProps.trackLongTasks,
    trackLongTasks = _staticProps$trackLon === void 0 ? true : _staticProps$trackLon,
    _staticProps$trackUse = staticProps.trackUserInteractions,
    trackUserInteractions = _staticProps$trackUse === void 0 ? true : _staticProps$trackUse,
    _staticProps$defaultP = staticProps.defaultPrivacyLevel,
    defaultPrivacyLevel = _staticProps$defaultP === void 0 ? 'mask-user-input' : _staticProps$defaultP;
  var _getConfig = getConfig(),
    config = _getConfig.publicRuntimeConfig,
    serverConfig = _getConfig.serverRuntimeConfig;
  if (!(serverConfig == null ? void 0 : (_serverConfig$datadog = serverConfig.datadog) == null ? void 0 : _serverConfig$datadog.enabled)) {
    return null;
  }
  var content = "\n    (function(h,o,u,n,d) {\n     h=h[d]=h[d]||{q:[],onReady:function(c){h.q.push(c)}}\n     d=o.createElement(u);d.async=1;d.src=n\n     n=o.getElementsByTagName(u)[0];n.parentNode.insertBefore(d,n)\n    })(window,document,'script','https://www.datadoghq-browser-agent.com/us5/v5/datadog-rum.js','DD_RUM')\n    window.DD_RUM.onReady(function() {\n      window.DD_RUM.init({\n        clientToken: '".concat(clientToken, "',\n        applicationId: '").concat(applicationId, "',\n        site: '").concat(datadogUrl, "',\n        service: '").concat(service, "',\n        env: '").concat(config.environment, "',\n        version: '").concat(config.appVersion, "',\n        sessionSampleRate: ").concat(serverConfig == null ? void 0 : (_serverConfig$datadog2 = serverConfig.datadog) == null ? void 0 : _serverConfig$datadog2.sessionSample, ",\n        sessionReplaySampleRate: ").concat(serverConfig == null ? void 0 : (_serverConfig$datadog3 = serverConfig.datadog) == null ? void 0 : _serverConfig$datadog3.sessionReplay, ",\n        trackResources: ").concat(trackResources, ",\n        trackLongTasks: ").concat(trackLongTasks, ",\n        trackUserInteractions: ").concat(trackUserInteractions, ",\n        defaultPrivacyLevel: '").concat(defaultPrivacyLevel, "',\n      });\n    })\n  ");
  return React.createElement("script", {
    "data-testid": "datadog"
    ,
    dangerouslySetInnerHTML: {
      __html: content
    }
  });
};
Datadog.ssr = true;
process.env.NODE_ENV !== "production" ? Datadog.propTypes = {
  "static": shape({
    applicationId: string.isRequired,
    clientToken: string.isRequired,
    datadogUrl: string,
    defaultPrivacyLevel: string,
    service: string.isRequired,
    sessionReplaySampleRate: number,
    sessionSampleRate: number,
    trackLongTasks: bool,
    trackResources: bool,
    trackUserInteractions: bool
  }).isRequired
} : void 0;
export default Datadog;